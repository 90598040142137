@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 481px) and (max-width: 1024px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

.hide-mobile {
  @include mobile {
    display: none !important;
  }
}

.hide-desktop {
  @include desktop {
    display: none !important;
  }
}
